<template>
  <div class="Storetable">
    <!-- 品牌交易排行/价位段交易排行-->
    <div style="margin-top: 10px;">
      <div class="BrandfaultBtn">
        <div
          v-for="item in tabList"
          :key="item.value"
          :class="filterForm.type == item.value ? 'active': 'none'"
          @click="filterForm.type = item.value"
        >{{item.label}}</div>
      </div>
    </div>
    <div class="btnStore">
      <!-- 分类 -->
      <div class="flex" style="margin-right: 10px">
        <div class="radiobtn" v-if="classList.length != 0">
          <div class="classBtn">
            <div
              v-for="item in classList"
              :key="item.id" :class="filterForm.machineTypeId == item.id ? 'active' : 'none'"
              @click="filterForm.machineTypeId = item.id">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content;">排序方式：</span>
        <el-select v-model="orderTypeTable" @change="merchanChange" placeholder="请选择" class="el-form-group_isRound">
          <el-option v-for="item in orderTypeOption" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content;">选择品牌：</span>
        <el-select v-model="filterForm.brandNo" @change="getModelList" placeholder="请选择品牌" filterable clearable class="el-form-group_isRound">
          <el-option v-for="item in brandList" :key="item.brandNo" :label="item.name" :value="item.brandNo">
          </el-option>
        </el-select>
      </div>
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content;">选择机型：</span>
        <el-select v-model="filterForm.modelId" :disabled="!filterForm.brandNo" placeholder="请先选择品牌" filterable clearable class="el-form-group_isRound">
          <el-option v-for="item in modelList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="btnStore">
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content;">选择商家：</span>
        <el-select v-model="filterForm.merchantId" placeholder="请选择回收商" filterable clearable class="el-form-group_isRound">
          <el-option v-for="item in merchantSelectList" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId">
          </el-option>
        </el-select>
        <el-select v-model="filterForm.companyId" placeholder="请选择门店商" filterable clearable class="el-form-group_isRound">
          <el-option v-for="item in companySelectList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="flex">
        <span style="flex-shrink: 0;">选择日期：</span>
        <div class="time-select">
          <div
            v-for="item in dateTypeList"
            :key="item.value"
            :class="['btn', filterForm.dateType == item.value ? 'btnType2' : 'btnType-after2']"
            @click="filterForm.dateType = item.value; dateChange(item.value)">
            {{ item.label }}
          </div>
        </div>
        <div>
            <!-- v-model="valueTime" -->
          <el-date-picker
            :value="[filterForm.startTime, filterForm.endTime]"
            type="daterange"
            range-separator="至"
            value-format="timestamp"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            clearable
            @input="datePickInput" />
        </div>
      </div>
      <div style="margin-left: 10px">
        <el-button type="primary" @click="search" round>查询</el-button>
      </div>
      <div v-if="filterForm.type !== '01'" style="margin-left: 10px">
        <!-- <el-button icon="el-icon-download" type="success" round @click="exportExcelBusinessFn">导出Excel</el-button> -->
        <el-button :loading="isExport" icon="el-icon-download" type="success" round @click="exportExcelBusiness">导出Excel</el-button>
      </div>
    </div>
    <!-- 机型交易排行 表格 -->
    
    <ModelRankTable
      ref="modelRankTable"
      v-if="filterForm.type === '03'"
      :data="tableData"
      :query="filterForm"/>
    <!-- 品牌/价位段 交易排行 表格 -->
    <BrandOrPriceRankTable
      ref="brandOrPriceRankTable"
      v-else
      :type="filterForm.type"
      />
    <!-- <el-table max-height="540" v-else :data="tableData" border style="width: 100%" v-loading="storetableLoading">
      <el-table-column prop="rank" label="排行" width="70" align="center" />
      <el-table-column prop="dataName" :label="filterForm.type === '02' ? '品牌' : '价位段'" align="center" />
      <el-table-column label="系统估价量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.assessCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="询价量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.inquiryCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成交量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dataCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成交率" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dataRate }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="累计交易金额（元）" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dealAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平均成交单价（元）" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.avgPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="回收商交易排行" align="center">
        <template slot-scope="{ row }">
          <div @click="merchantDialog(row, 'merchant')" class="tableBtn">
            查看
          </div>
        </template>
      </el-table-column>
      <el-table-column label="门店商交易排行" align="center">
        <template slot-scope="{ row }">
          <div @click="storeDialog(row,'company')" class="tableBtn">
            查看
          </div>
        </template>
      </el-table-column>
    </el-table> -->
    <!-- 回收商交易排行 -->
    <DialogTbale @onClose="onMerchantRankClose" :loading="merchantStoreLoading" :show="merchantRank.show" :isIndex="false"
      :title="merchantRank.title" :columns="merchantColumns" :data="dialogList" :currentPage="merchantStoreParams.pageNum"
      :pageSize="merchantStoreParams.pageSize" :total="merchantStoreParams.total" :width="1000"
      @handleCurrentChange="getstoreMerRankList" :maxHeight="540">
      <div style="display: flex;align-items: center;margin-bottom: 20px;">
        <div style="margin-right: 30px;"><span>时间：{{ showData }}</span></div>
        <!-- <div><span>排行方式：{{ orderTypeTable === '01' ? '累计成交量排行' : orderTypeTable === '02' ? '累计询价量排行' : '成交率' }}</span></div> -->
      </div>
      <el-table-column
        label="最高报价量"
        slot="highestQuoteNum"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.highestQuoteNum || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="最高报价率"
        slot="highestQuoteRatio"
        align="center"
      >
      <template slot="header">
         最高报价率
          <el-tooltip
            class="item"
            effect="dark"
            content="最高报价量/有效报价量*100%"
            placement="top"
          >
            <i class="el-icon-warning-outline" />
          </el-tooltip>
        </template>
        <template slot-scope="{ row }">
          <span>{{ row.highestQuoteRatio || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成交率" slot="dataRate" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dataRate || 0 }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="报价率" slot="quoteRate" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.quoteRate || 0 }}%</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 门店商交易排行-->
    <DialogTbale @onClose="onStoreRankClose" :loading="merchantStoreLoading" :show="storeRank.show"
      :title="storeRank.title" :isIndex="false" :columns="storeColumns" :data="dialogList"
      :currentPage="merchantStoreParams.pageNum" :total="merchantStoreParams.total"
      :maxHeight="540"
      :pageSize="merchantStoreParams.pageSize" :width="1000" @handleCurrentChange="getstoreMerRankList">
      <div style="display: flex;align-items: center;margin-bottom: 20px;">
        <div style="margin-right: 30px;"><span>时间：{{showData }}</span></div>
        <!-- <div><span>排行方式：{{ orderTypeTable === '01' ? '累计成交量排行' : orderTypeTable === '02' ? '累计询价量排行' : '成交率' }}</span></div> -->
      </div>
      <el-table-column label="成交率" slot="dataRate" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dataRate || 0 }}%</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 操作提示弹框 -->
    <el-dialog title='操作提示' :visible.sync="commandShow" :close-on-click-modal="false" width="550px"
      @closed="commandClosed">
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click.native="commandSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from "moment";
import ModelRankTable from './ModelRankTable.vue';
import BrandOrPriceRankTable from './BrandOrPriceRankTable.vue';
export default {
  name: "BrandTable",
  components: {
    ModelRankTable,
    BrandOrPriceRankTable
  },
  props: {
    merchantSelectList: {
      type: Array,
      default: () => []
    },
    companySelectList: {
      type: Array,
      default: () => []
    },
    isManufacturers: {
      type: String,
      default: ""
    }
  },
  
  data() {
    return {
      channel: '',//商家类型（company:门店商，merchant:回收商）

      tabList: [
        { label: '品牌交易排行', value: '02'},
        { label: '机型交易排行', value: '03'},
        { label: '价位段交易排行', value: '01'},
      ],
      dateTypeList: [
        { label: '今日', value: '01'},
        { label: '本周', value: '03'},
        { label: '本月', value: '05'},
      ],
      classList: [],//分类数据：手机/平板/笔记本/其他
      brandList: [], // 品牌列表
      modelList: [], // 机型列表
      filterForm: { // 筛选条件
        machineTypeId: 1,
        type: '02',
        startTime: undefined,
        endTime: undefined,
        dateType: '01',
        merchantId: '',
        companyId: '',
        brandNo: '', // 品牌编码
        modelId: '',
        isManufacturers: ''
      },
      isSearchForm: {}, // 已经请求过接口的筛选条件

      storeRank: {//门店排行：品牌/价位段
        show: false,
        title: ''
      },
      dialogList: [],
      storeLoading: false,
      storeColumns: [
        { label: "排行", prop: "rank" },
        { label: "门店商家", prop: "dataName" },
        { label: "系统估价量", prop: "assessCount" },
        { label: "询价量", prop: "inquiryCount" },
        { label: "成交量", prop: "dataCount" },
        { slotName: "dataRate" },
        { label: "交易金额", prop: "dealAmount" },
        { label: "补贴金额", prop: "renewSubsidyPriceAll" },
      ],
      // ***********
      key: '',
      merchantRank: {//回收商排行：品牌/价位段
        show: false,
        title: ''
      },
      merchantStoreLoading: false,
      merchantStoreParams: {
        pageNum: 1,
        pageSize: 9999,
        total: 0
      },
      merchantColumns: [
        { label: "排行", prop: "rank" },
        { label: "回收商家", prop: "dataName" },
        { label: "接收询价量", prop: "inquiryCount" },
        { label: "报价量", prop: "quoteCount" },
        { slotName: "highestQuoteNum" },
        { slotName: "highestQuoteRatio" },
        { label: "成交量", prop: "dataCount" },
        { slotName: "dataRate" },
        { slotName: "quoteRate" },
        { label: "交易金额", prop: "dealAmount" },
      ],
      // ***************
      storetableLoading: false,
      commandShow: false,
      commandBtnLoading: false,
      command: "",
      tableData: [],
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      valueTime: "",
      startTime: "",
      endTime: "",
      dateType: "01",
      type: "",
      merchantId: "",
      orderTypeTable: "01",
      orderTypeOption: [{
        label: '累计成交量排行',
        value: '01'
      }, {
        label: '累计询价量排行',
        value: '02'
      }, {
        label: '成交率',
        value: '03'
      }],
      className: '',
      isExport: false,
    };
  },
  watch: {
    'filterForm.type': function (v) {
      this.search()
    },
    'filterForm.machineTypeId': function (v) {
      const { classList } = this
      this.className = classList.find(item => item.id == v)?.name
      this.getBrandList()
      this.getModelList()
      this.search()
    },
    'filterForm.dateType': function (v) {
      this.search()
    },
    isManufacturers() {
      this.filterForm.isManufacturers = this.isManufacturers
      this.search()
    }
  },
  computed: {
    showData: function() { //弹框展示的查询时间
      const { dateType, startTime, endTime } = this.filterForm
      if (dateType) return this.dateTypeList.find(item => item.value == dateType)?.label
      return `${moment(startTime).format("YYYY.MM.DD")} - ${moment(endTime).format("YYYY.MM.DD")}`
    },
  },
  provide() {
    return {
      // filterForm: this.filterForm,
      className: () => this.className,
      showData: () => this.showData
    }
  },
  created() {
    this.getClassList();
    // this.getBrandList()
  },
  methods: {
    // 获取分类集合 手机/平板/笔记本/其他
    getClassList() {
      _api.selectList().then((res) => {
        this.classList = res.data ? res.data : [];
        this.filterForm.machineTypeId = res.data[0].id
      });
    },
    // 获取旧机品牌列表
    getBrandList() {
      this.filterForm.brandNo = ''
      _api.oldBrandSelectByType({ typeId: this.filterForm.machineTypeId }).then(res => {
        this.brandList = res.data || []
      })
    },
    // 获取机型
    getModelList() {
      this.filterForm.modelId = ''
      this.modelList = []
      const { brandList, filterForm  } = this
      const { machineTypeId, brandNo } = filterForm
      const brandId = brandList.find(item => brandNo == item.brandNo)?.id || ''
      if (machineTypeId && brandId) {
        _api.listByTypeBrand({
          type: '00',
          brandId,
          machineTypeId
        }).then(res => {
          this.modelList = res.data
        })
      }
    },
    // 日周月切换处理
    dateChange(v) {
      this.filterForm.dateType = v
      this.filterForm.startTime = undefined
      this.filterForm.endTime = undefined
    },
    // 日期选择处理
    datePickInput(v) {
      const [ startTime, endTime ] = v || []
      this.filterForm.startTime = startTime
      this.filterForm.endTime = endTime
      this.filterForm.dateType= v ? '' : '01'
    },
    // 搜索
    search() {
      this.$nextTick(() => {
        const { filterForm } = this
        const { type } = filterForm
        if (type == '03') {
          this.$refs['modelRankTable'].init(filterForm)
        } else {
          this.$refs['brandOrPriceRankTable'].init(filterForm)
        }
      })
      // return
      // this.storetableLoading = true
      // this.isSearchForm = JSON.parse(JSON.stringify(this.filterForm))
      // const loading = this.$loading({
      //   lock: true,
      //   text: '正在加载数据中，请耐心等待！',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.6)'
      // });
      // let request = _api.brandPriceRankList
      // if (this.filterForm.type == '03') request = _api.machineRankStatistics
      // request(this.filterForm).then((res) => {
      //   if (this.filterForm.type == '03') {
      //     this.tableData = res.data.records
      //   } else {
      //     this.tableData = res.data
      //   }
      //   loading.close();
      //   this.storetableLoading = false
      // }).catch(ree => {
      //   loading.close();
      // });
    },
    // 回收商排行弹框
    merchantDialog(row, channel) {
      this.channel = channel
      this.key = row.key
      this.merchantRank = {
        show: false,
        title: ''
      }
      if (this.filterForm.type == '02') {
        this.merchantRank = {
          show: true,
          title: '品牌排行'
        }
      } else {
        this.merchantRank = {
          show: true,
          title: '价位段排行'
        }
      }
      this.getstoreMerRankList()
    },
    onMerchantRankClose() {
      this.merchantRank = {
        show: false,
        title: ''
      }
    },
    // 门店排行弹框
    storeDialog(row, channel) {
      this.channel = channel
      this.key = row.key
      this.storeRank = {
        show: false,
        title: ''
      }
      if (this.filterForm.type == '02') {
        this.storeRank = {
          show: true,
          title: '品牌排行'
        }
      } else {
        this.storeRank = {
          show: true,
          title: '价位段排行'
        }
      }
      this.getstoreMerRankList()
    },
    getstoreMerRankList(val) {
      this.dialogList = []
      if (val) {
        this.merchantStoreParams.pageNum = val;
      } else {
        this.merchantStoreParams.pageNum = 1;
      }
      let params = {
        key: this.key,
        channel: this.channel,
        pageNum: this.merchantStoreParams.pageNum,
        pageSize: this.merchantStoreParams.pageSize,
        ...this.filterForm
      }
      _api.brandPriceRankDown(params).then(res => {
        if (res.code === 1) {
          this.dialogList = res.data.records
          this.merchantStoreParams.pageNum = res.data.current;
          this.merchantStoreParams.total = res.data.total;
        }
      })
    },
    onStoreRankClose() {
      this.storeRank = {
        show: false,
        title: ''
      }
    },
    exportExcelBusinessFn() {
      this.commandShow = true
    },
    commandClosed() {
      this.commandShow = false
      this.command = ""
    },
    commandSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      this.exportExcelBusiness()
    },
    exportExcelBusiness() {
      let data = {
        // type: "01",
        // command: this.command,
        ...this.filterForm
      }
      this.commandBtnLoading = true
      let request = _api.brandOrPriceRankStatisticsExcel
      if (data.type === '03') request = _api.machineRankStatisticsExcel
      this.isExport=true
      request(data).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.commandBtnLoading = false
            }
          } catch (err) {
            let start = "";
            let end = ""
            if (data.startTime || data.endTime) {
              start = moment(Number(data.startTime)).format("YYYY.MM.DD")
              end = moment(Number(data.endTime)).format("YYYY.MM.DD")
            } else if (data.dateType == '01') {
              start = moment().month("YYYY-MM-DD").format('YYYY.MM.DD')
            } else if (data.dateType == '03') {
              start = moment().weekday(1).format('YYYY.MM.DD')
              end = moment().month("YYYY-MM-DD").format('YYYY.MM.DD')
            } else if (data.dateType == '05') {
              start = moment().startOf('month').format('YYYY.MM.DD')
              end = moment().month("YYYY-MM-DD").format('YYYY.MM.DD')
            }
            const fileName = `【${this.optionsFilter(this.filterForm.type, this.tabList)}】${start || ''}${end ? '-' + end : ''}.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandBtnLoading = false
            this.commandShow = false
          }
        });
        reader.readAsText(blob, "utf-8");
        this.isExport=false
      }).catch(err=>{
        this.isExport=false
      });
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.BrandfaultBtn {
  width: 380px;
  height: 40px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  // border-radius: 21px;
  display: flex;
  cursor: pointer;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    background: #0981ff;
    // border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    border-radius: 20px;
  }
}

.classBtn {
  width: 400px;
  height: 40px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  cursor: pointer;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    background: #0981ff;
    border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    border-radius: 20px;
  }
}

.Storetable {
  padding: 0 20px 20px;

  .orderPagina {
    padding: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }

  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }

  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .btnStore {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    margin: 22px 0;

    .time-select {
      display: flex;

      .btn {
        box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
        margin: 0px 10px 0px 0;
        text-align: center;
        cursor: pointer;
      }

      .btnType-after2 {
        // width: 33%;
        width: 80px;
        min-width: max-content;
        height: 40px;
        background: #ffffff;
        color: #0981ff;
        line-height: 40px;
        border-radius: 20px;
      }

      .btnType2 {
        width: 80px;
        min-width: max-content;
        height: 40px;
        background: #0981ff;
        color: white;
        line-height: 40px;
        border-radius: 20px;
      }
    }
  }

  .merchant-dialog {
    /deep/.el-dialog__body {
      padding: 21px 20px 20px 20px;
    }
  }

  .button_top {
    margin-bottom: 20px;
    ;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 240px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    justify-content: space-between;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
