<template>
  <div class="phoneTable">
    <div style="margin-top: 10px;display: flex;align-items: center;">
       <!-- 胶囊切换 -->
      <div class="button_top">
        <div class="faultBtn">
          <div :class="manufacturersType === '' ? 'active' : 'none'" @click="btnClick('')">
            全部
          </div>
          <div :class="manufacturersType === '01' ? 'active' : 'none'" @click="btnClick('01')">
            KA商家
          </div>
          <div :class="manufacturersType === '00' ? 'active' : 'none'" @click="btnClick('00')">
            厂商
          </div>
        </div>
      </div>
      <div class="flex" v-if="manufacturersType==='01'" style="margin-right: 10px">
        <span style="min-width: max-content;">商户类型：</span>
        <el-select v-model="matrixType" filterable clearable @change="companyChange" placeholder="请选择"
          class="el-form-group_isRound">
          <el-option v-for="item in commercialtenantList" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="btnStore">
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content;">门店商家：</span>
        <el-select v-model="seachCompanyId" filterable clearable @change="companyChange" placeholder="请选择"
          class="el-form-group_isRound">
          <el-option v-for="item in companySelectList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content;">排序方式：</span>
        <el-select v-model="orderTypeTable" @change="companyChange" placeholder="请选择" class="el-form-group_isRound">
          <el-option v-for="item in orderTypeOption" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="time-select">
        <div class="btn" :class="dateType == '01' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('01')">
          今日
        </div>
        <div class="btn" :class="dateType == '03' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('03')">
          本周
        </div>
        <div class="btn" :class="dateType == '05' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('05')">
          本月
        </div>
      </div>
      <div>
        <el-date-picker v-model="valueTime" type="daterange" range-separator="至" value-format="timestamp"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </div>
      <div style="margin-left: 10px">
        <el-button type="primary" @click="seachStore" round>查询</el-button>
      </div>
      <div style="margin-left: 10px">
        <el-button icon="el-icon-download"  type="success" round @click="exportExcelStoreFn">导出Excel</el-button>
      </div>
    </div>
    <el-table ref="tableList" :data="tableData" :default-sort="{prop:'dealCount',order:'descending'}" @sort-change="sortTable" v-loading="phoneTableLoading" border style="width: 100%" :cell-style="Stylecell"
      @cell-click="cellclick">
      <el-table-column prop="rownum" label="序号" width="70" align="center">
        <template slot-scope="{ row }">
          <div class="numBG_1" v-if="row.rownum === 1">1</div>
          <div class="numBG_2" v-else-if="row.rownum === 2">2</div>
          <div class="numBG_3" v-else-if="row.rownum === 3">3</div>
          <div v-else>{{ row.rownum }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="手机商" align="center"></el-table-column>
      <el-table-column prop="storeCount" label="门店数量" sortable="custom" :sort-orders="['descending', 'ascending']" width="90" align="center" ></el-table-column>
      <el-table-column prop="storeQuoteNum" label="有效询价门店" sortable="custom" :sort-orders="['descending', 'ascending']" align="center" ></el-table-column>
      <el-table-column prop="merchantNum" label="合作中回收商" sortable="custom" :sort-orders="['descending', 'ascending']" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.merchantNum||0 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="assessCount" label="系统估价量" sortable="custom" :sort-orders="['descending', 'ascending']"  align="center">
        <template slot-scope="{ row }">
          <a class="fc_line" v-if="row.assessCount" target="_blank"
            :href="`/RecallOrder/evaluate?companyId=${row.id}&companyName=${row.name}`">
            {{ row.assessCount }}
          </a>
          <div v-else class="f_line">--</div>
        </template>
      </el-table-column>
      <el-table-column prop="inquiryCount" label="累计询价量" sortable="custom" :sort-orders="['descending', 'ascending']" align="center">
        <template slot-scope="{ row }">
          <div @click="storeDialog(row, '01')" class="tableBtn" v-if="row.inquiryCount">
            <span style="border-bottom: 1px solid #0981ff">{{
              row.inquiryCount
            }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column prop="biddingNum" label="竞价成功" sortable="custom" :sort-orders="['descending', 'ascending']" align="center">
        <template slot-scope="{ row }">
            <span >{{
              row.biddingNum||0
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealCount" label="累计成交量" sortable="custom" :sort-orders="['descending', 'ascending']"  align="center">
        <template slot-scope="{ row }">
          <div @click="storeDialog(row, '02')" class="tableBtn" v-if="row.dealCount">
            <span style="border-bottom: 1px solid #0981ff">{{
              row.dealCount
            }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealPrice" sortable="custom" :sort-orders="['descending', 'ascending']" label="累计交易额"  align="center"></el-table-column>
      <el-table-column prop="avgPrice" sortable="custom" :sort-orders="['descending', 'ascending']" label="平均成交单价（元）"  width="130" align="center"></el-table-column>
      <!-- <el-table-column
        prop="profitRatio"
        label="毛利率"
        align="center"
      ></el-table-column> -->
      <el-table-column prop="profitRatio" sortable="custom" :sort-orders="['descending', 'ascending']" label="总毛利率" width="120" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总毛利率</div>
            <div>
              <el-tooltip class="item" effect="dark" content="总毛利率=毛利额/回收总额*100%" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.profitRatio || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rebateRatio" sortable="custom" :sort-orders="['descending', 'ascending']" label="净利率" width="115" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>净利率</div>
            <div>
              <el-tooltip class="item" effect="dark" content="公司净利润指公司获得的利润额，不含员工奖励" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.rebateRatio || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealRatio" sortable="custom" :sort-orders="['descending', 'ascending']" label="成交率" align="center"></el-table-column>
      <el-table-column prop="aprNum" label="差异量" align="center"></el-table-column>
      <el-table-column prop="aprRate" label="差异率" align="center">
        <template slot-scope="{ row }">
          <span>{{row.aprRate}}%</span>
        </template>
      </el-table-column>
      <el-table-column label="查看商家数据" align="center" fixed="right" width="160">
        <template slot-scope="{ row }">
          <span style="text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="jumpPage(row, 'home')">首页数据</span><span
            style="margin-left:20px;text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="jumpPage(row, 'ratio')">环比数据</span>
        </template>
      </el-table-column>
      <el-table-column label="查看补拍数据" align="center" fixed="right"><span
          style="text-decoration: underline; cursor: pointer; color: #0981ff">进入查看</span></el-table-column>
      <el-table-column label="备注" align="center" fixed="right" width="160">
        <template slot-scope="{ row }">
          <span style="text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="remarksClick(row, 'see')">备注记录</span>
          <span style="margin-left:20px;text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="remarksClick(row, 'add')">添加备注</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="orderPagina">
      <el-pagination float="right" background :current-page="this.page.pageNum" :page-size="this.page.pageSize"
        layout="total, prev, pager, next,jumper" :total="this.page.total" class="el-pagination-c"
        @current-change="handleCurrentChange" />
    </div>
    <el-dialog class="store-dialog" title="门店商家成交统计" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="800px">
      <div class="flex flex-jb" style="margin-bottom: 20px">
        <div>
          <el-select v-model="orderType" placeholder="请选择" size="small" @change="companyDealMerchant">
            <el-option v-for="item in rankingSelectList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          时间：<span v-if="dateType">{{
            dateType == "01"
            ? "今日"
            : dateType == "03"
              ? "本周"
              : dateType == "05"
                ? "本月"
                : ""
          }}</span>
          <span v-else>{{ format(startTime) + "~" + format(endTime) }}</span>
        </div>
      </div>
      <GlobalTable ref="GlobalTable" v-loading="storeloading" :columns="storeColumns" :maxHeight="500"
        :data="storeDataList" :isPagination="false" :isIndex="false">
        <el-table-column
          slot="name"
          label="回收商家"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.name }}</span>
            <span class="isdeal1" v-if="row.isOutMerchant">外部</span>
          </template>
        </el-table-column>
        <el-table-column prop="quote" label="报价量" slot="quoteCount" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.quote }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="dealRate" label="成交率" slot="dealRate" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.dealRate + "%" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <!-- <GlobalTable ref="GlobalTable" v-loading="storeloading" :columns="storeColumns" :maxHeight="500"
        :data="storeDataList" :isPagination="false" :isIndex="false">
        <el-table-column prop="quote" label="报价量" slot="quoteCount" sortable align="center">
          <template slot-scope="{ row }">
            <span>{{ row.quote }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="dealRate" label="成交率" slot="dealRate" sortable align="center">
          <template slot-scope="{ row }">
            <span>{{ row.dealRate + "%" }}</span>
          </template>
        </el-table-column>
      </GlobalTable> -->
    </el-dialog>
    <DialogTbale :show="askVisible" :loading="loading" title="门店商家报价统计" :columns="DialogTableColumns"
      :data="DialogDataList" :currentPage="dialogPage.pageNum" :total="dialogPage.total" :pageSize="dialogPage.pageSize"
      :width="900" :isIndex="false" @onClose="onClose" @handleCurrentChange="handleCurrentChangeDialog">
      <div class="flex" style="margin-bottom: 20px">
        <div>
          <span style="width: 93px">门店名称：</span>
          <el-select v-model="storeId" filterable size="small" clearable placeholder="请选择或输入门店名称">
            <el-option v-for="item in companyList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div class="flex" style="margin-left: 20px">
          <span style="width: 93px">员工姓名：</span>
          <el-input v-model="staffName" size="small" placeholder="请输入员工姓名"></el-input>
        </div>
        <el-button type="primary" style="margin-left: 10px" size="small" @click="searchSetting">
          查询
        </el-button>
      </div>
      <el-table-column label="询价员工" slot="staffName" align="center">
        <template slot-scope="{ row }">
          <el-tag style="margin-right: 10px" size="mini" :type="row.isMajor
              ? ''
              : row.staffType == '01'
                ? 'danger'
                : row.staffType == '02'
                  ? 'success'
                  : 'warning'
            " effect="dark">
            {{
              row.isMajor
              ? "创建人"
              : row.staffType == "01"
                ? "子账号"
                : row.staffType == "02"
                  ? "总监"
                  : row.staffType == "03"
                    ? "店长"
                    : "店员"
            }}
          </el-tag>
          <span>{{ row.staffName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="询价门店" slot="storeName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成交率" slot="dealRate" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dealRate + "%" }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <el-dialog title='操作提示' :visible.sync="commandShow" :close-on-click-modal="false" width="550px"
      @closed="commandClosed">
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click.native="commandSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 备注弹出框 -->
    <el-dialog title="添加备注" :visible.sync="RemarksShow" :close-on-click-modal="false" width="500px"
      @closed="handleDialogClosed">
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input maxlength="500" type="textarea" :rows="4" placeholder="请输入备注内容,最多500字" v-model="contentRemarks">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="RemarksShow = false">取消</el-button>
        <el-button type="primary" @click.native="Remarks_log_sure">确定</el-button>
      </span>
    </el-dialog>
    <DialogTbale :show="remarksVisible" :loading="remarksLoading" title="备注记录" :columns="remarksDialogTable"
      :data="remarksDialogDataList" :currentPage="remarkDialogPage.pageNum" :total="remarkDialogPage.total"
      :pageSize="remarkDialogPage.pageSize" :width="850" @onClose="remarksVisible = false"
      @handleCurrentChange="remarkCurrentChangeDialog">
      <div class="flex" style="margin-bottom: 20px">
        <div class="flex" style="margin-left: 20px">
          <span style="width: 93px">备注人：</span>
          <el-input v-model="noteUserName" size="small" placeholder="请输入备注人姓名查询" clearable></el-input>
        </div>
        <el-button type="primary" style="margin-left: 10px" size="small" @click="remarkCurrentChangeDialog(1)">
          查询
        </el-button>
      </div>
      <el-table-column label="备注内容" slot="content" width="400" align="center">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.content" placement="top">
            <div class="Remarks">
              {{ row.content }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "phoneTable",
  props: ['companySelectList'],
  data() {
    return {
      manufacturersType:'',
      commercialtenantList:[{
        name:"全部商户",
        value:"00",
      },
      {
        name:"矩阵商户",
        value:"01",
      },{
        name:"普通商户",
        value:"02",
      }],
      tableData: [],
      phoneTableLoading: false,
      commandBtnLoading: false,
      commandShow: false,
      RemarksShow: false,
      remarksVisible: false,
      remarksLoading: false,
      contentRemarks: "",
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      dialogPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      remarkDialogPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      valueTime: "",
      startTime: "",
      endTime: "",
      dateType: "01",
      seachCompanyId: "",
      storeId: "",
      staffName: "",
      companyId: "",
      command: "",
      orderType: "02",
      orderTypeTable: "04",
      ackOrDesc:'01', // 降序：01，升序：02
      columnType: '04', // 排序类别【门店数量：01，系统估价量：02，累计询价量：03，累计成交量：04，累计成交额：05，平均成交价：06，总毛利：07，净利率：08，成交率：09】
      dialogVisible: false,
      askVisible: false,
      loading: false,
      storeloading: false,
      companyList: [],
      storeDataList: [],
      DialogDataList: [],
      storeColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "name" },//回收商家
        { slotName: "quoteCount" },
        { label: "有效报价量", prop: "normalQuoteNum"},
        { label: "成交量", prop: "deal" },
        { label: "交易额(元)", prop: "dealAmount" },
        { label: "毛利贡献(元)", prop: "grossProfit" },
        { slotName: "dealRate" },
        { label: "补贴金额", prop: "renewSubsidyPriceAll" },
      ],
      // storeColumns: [
      //   { label: "排行", prop: "rank" },
      //   { label: "回收商家", prop: "name" },
      //   { slotName: "quoteCount" },
      //   { label: "成交量", prop: "deal",sortable:true },
      //   { label: "交易额(元)", prop: "dealAmount",sortable:true },
      //   { label: "毛利贡献(元)", prop: "grossProfit",sortable:true },
      //   { slotName: "dealRate" },
      // ],
      DialogTableColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "staffName" },
        { slotName: "storeName" },
        { label: "询价量", prop: "inquiryCount" },
        { label: "成交量", prop: "dealCount" },
        { slotName: "dealRate" },
      ],
      rankingSelectList: [
        {
          value: "02",
          label: "按成交量排行",
        },
        {
          value: "04",
          label: "按交易额排行",
        },
        {
          value: "03",
          label: "按毛利贡献排行",
        },
      ],
      orderTypeOption: [{
        label: '按成交量由高到低',
        value: '04'
      }, {
        label: '按询价量由高到低',
        value: '03'
      }, {
        label: '按成交率由高到低',
        value: '09'
      }, {
        label: '按门店数量由多到少',
        value: '01'
      },{
        label: '自定义排序',
        value: '00'
      }],
      paramsData: {},
      remarksId: "",
      noteUserName: "",
      remarksDialogTable: [
        { label: "备注时间", prop: "createTime" },
        { label: "备注人", prop: "noteUserName" },
        { slotName: "content" },
      ],
      remarksDialogDataList: [],
      matrixType: "00",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    btnClick(type){
      if(type !== '01'){
        this.matrixType = '00'
      }
       this.manufacturersType=type
       this.handleCurrentChange()
    },
    // 列表排序
    sortTable(column){
      console.log('===',column);
      
      if(column.prop){
        // 排序方式
        if(column.order == 'ascending'){
          this.ackOrDesc = '02'
        }else if(column.order == 'descending'){
          this.ackOrDesc = '01'
        }

        // 排序字段
        switch (column.prop) {
          case 'storeCount':
            this.columnType = '01';
            break;
          case 'merchantNum':
            this.columnType = '10';
            break;
          case 'assessCount':
            this.columnType = '02';
            break;
          case 'inquiryCount':
            this.columnType = '03';
            break;
          case 'dealCount':
            this.columnType = '04';
            break;
          case 'dealPrice':
            this.columnType = '05';
            break;
          case 'avgPrice':
            this.columnType = '06';
            break;
          case 'profitRatio':
            this.columnType = '07';
            break;
          case 'rebateRatio':
            this.columnType = '08';
            break;
          case 'dealRatio':
            this.columnType = '09';
            break;
          case 'storeQuoteNum':
            this.columnType = '11';
            break;
            case 'biddingNum':
            this.columnType = '12';
            break;
          default:
            this.columnType = '04';
        }

        this.orderTypeTable = '00'
      }
      this.handleCurrentChange()

    },
    // cell样式
    Stylecell({ row, column, rowIndex, columnIndex }) {
      // if (columnIndex == 8) {
      //   return "text-decoration:underline;color:#0981ff";
      // }
    },
    // cell方法
    cellclick(row, column, cell, event) {
      if (column.label == "查看补拍数据") {
        console.log(123);
        const { href } = this.$router.resolve({
          name: "补拍统计",
          query: {
            id: row.id,
            name: row.name,
          },
        });
        window.open(href, "_blank");
      }
    },
    jumpPage(row, type) {
      if (type == 'home') {
        const { href } = this.$router.resolve({
          path: "/payment/storeHome",
          query: {
            id: row.id,
            name: row.name,
            manufacturersType:row.manufacturersType
          },
        });
        window.open(href, "_blank");
      }
      if (type == 'ratio') {
        const { href } = this.$router.resolve({
          path: "/payment/storeRatio",
          query: {
            id: row.id,
            name: row.name,
          },
        });
        window.open(href, "_blank");
      }
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      if (val) {
        this.page.pageNum = val;
      }else{
        this.page.pageNum = 1;
      }
      const baseRequest = {
        ackOrDesc: this.ackOrDesc,
        orderType: this.columnType,
        matrixType: this.matrixType,
        manufacturersType: this.manufacturersType,
        // command: "",
        dateType: this.dateType,
        endTime: this.endTime,
        companyId: this.seachCompanyId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        startTime: this.startTime
      };
      // const baseRequest = {
      //   companyId: this.seachCompanyId,
      //   type: "02",
      //   startTime: this.startTime,
      //   endTime: this.endTime,
      //   pageNum: this.page.pageNum,
      //   pageSize: this.page.pageSize,
      //   dateType: this.dateType,
      //   orderType: this.orderTypeTable,
      // };
      this.phoneTableLoading = true
      this.paramsData = baseRequest
      const loading = this.$loading({
          lock: true,
          text: '正在加载数据中，请耐心等待！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.6)'
        });
      _api.HomeOrderListMen(baseRequest).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
        }
        loading.close();
        this.phoneTableLoading = false
      }).catch(ree=>{
          loading.close();
      });
    },
    //时间选择点击事件
    dataObtain(way) {
      this.dateType = way;
      this.valueTime = "";
      this.startTime = "";
      this.endTime = "";
      this.handleCurrentChange();
    },
    seachStore() {
      if (this.valueTime !== null && this.valueTime) {
        (this.dateType = ""),
          (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        if (!this.dateType) {
          (this.dateType = "01"), (this.startTime = ""), (this.endTime = "");
        }
      }
      this.handleCurrentChange();
    },
    storeDialog(row, type) {
      console.log(row);
      this.companyId = row.id;
      if (type == "01") {
        this.askVisible = true;
        this.getStoreSelectList();
        this.handleCurrentChangeDialog();
      } else {
        this.dialogVisible = true;
        this.companyDealMerchant();
      }
    },
    companyDealMerchant() {
      const baseRequest = {
        orderType: this.orderType,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType: this.dateType,
        companyId: this.companyId,
      };
      this.storeloading = true;
      _api.companyDealMerchant(baseRequest).then((res) => {
        if (res.code === 1) {
          this.storeDataList = res.data;
          this.storeloading = false;
        }
      });
    },
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    onClose() {
      this.askVisible = false;
      this.storeId = "";
      this.staffName = "";
    },
    searchSetting() {
      this.handleCurrentChangeDialog();
    },
    handleCurrentChangeDialog(val) {
      console.log(val);
      if (val) {
        this.dialogPage.pageNum = val;
      } else {
        this.dialogPage.pageNum = 1;
      }
      const baseRequest = {
        startTime: this.startTime,
        endTime: this.endTime,
        dateType: this.dateType,
        companyId: this.companyId,
        storeId: this.storeId,
        staffName: this.staffName,
        pageNum: this.dialogPage.pageNum,
        pageSize: this.dialogPage.pageSize,
      };
      this.loading = true;
      _api.companyStaffStatistics(baseRequest).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.dialogPage.pageNum = res.data.current;
          this.dialogPage.total = res.data.total;
          this.loading = false;
        }
      });
    },
    format(time) {
      let times = moment(time).format("YYYY-MM-DD");
      return times;
    },
    // 商家查询
    companyChange(e) {
      this.$refs.tableList.clearSort()
      this.ackOrDesc = '01'
      this.columnType = this.orderTypeTable
      this.seachStore()
    },
    exportExcelStoreFn() {
      this.commandShow = true
    },
    commandClosed() {
      this.commandShow = false
      this.command = ""
    },
    commandSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      this.exportExcelStore()
    },
    exportExcelStore() {
      let data = {
        type: "02",
        command: this.command,
        ...this.paramsData
      }
      this.commandBtnLoading = true
      _api.platformOrderRankListExcelMen(data).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.commandBtnLoading = false
            }
          } catch (err) {
            let start = "";
            let end = ""
            if (data.startTime || data.endTime) {
              start = moment(Number(data.startTime)).format("YYYY.MM.DD")
              end = moment(Number(data.endTime)).format("YYYY.MM.DD")
            } else if (data.dateType == '01') {
              start = moment().month("YYYY-MM-DD").format('YYYY.MM.DD')
            } else if (data.dateType == '03') {
              start = moment().weekday(1).format('YYYY.MM.DD')
              end = moment().month("YYYY-MM-DD").format('YYYY.MM.DD')
            } else if (data.dateType == '05') {
              start = moment().startOf('month').format('YYYY.MM.DD')
              end = moment().month("YYYY-MM-DD").format('YYYY.MM.DD')
            }
            console.log(moment().startOf('month'));
            const fileName = `【门店商交易排行】${start || ''}${end ? '-' + end : ''}.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandBtnLoading = false
            this.commandShow = false
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    remarksClick(row, type) {
      this.remarksId = row.id
      if (type == 'add') {
        this.RemarksShow = true
      } else {
        this.remarksVisible = true
        this.remarkCurrentChangeDialog(1)
      }
    },
    // 查看备注列表
    remarkCurrentChangeDialog(val) {
      if (val) {
        this.remarkDialogPage.pageNum = val
      }
      let params = {
        companyId: this.remarksId,
        noteUserName: this.noteUserName,
        pageNum: this.remarkDialogPage.pageNum,
        pageSize: this.remarkDialogPage.pageSize,
      }
      this.remarksLoading = true
      _api.getNoteCompany(params).then(res => {
        if (res.code == 1) {
          this.remarksDialogDataList = res.data.records
          this.remarkDialogPage.pageNum = res.data.current;
          this.remarkDialogPage.total = res.data.total
        }
        this.remarksLoading = false
      })
    },
    // 关闭备注弹框回调
    handleDialogClosed() {
      this.contentRemarks = ""
      this.remarksId = ""
    },
    // 添加门店商备注
    Remarks_log_sure() {
      if (this.contentRemarks.match(/^\s+$/) || !this.contentRemarks) {
        this.$message.error('请输入备注内容')
        return
      }
      _api.noteCompany({ companyId: this.remarksId, content: this.contentRemarks }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg || '添加成功')
          this.RemarksShow = false
        }
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.fc_line {
  color: #0981FF;
  text-decoration: underline;
  cursor: pointer;
}

.f-line {
  text-decoration: underline;
}
.phoneTable {
  padding: 0 20px 20px;

  .img-el-heard {
    display: flex;
    justify-content: center;

    img {
      margin: 0px 0 -3px 3px;
    }
  }

  .orderPagina {
    padding: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }

  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }

  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .store-dialog {
    /deep/.el-dialog__body {
      padding: 21px 20px 20px 20px;
    }
  }

  .btnStore {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 22px 0;

    .time-select {
      display: flex;

      .btn {
        box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
        margin: 0px 10px 0px 0;
        text-align: center;
        cursor: pointer;
      }

      .btnType-after2 {
        // width: 33%;
        width: 80px;
        min-width: max-content;
        height: 40px;
        background: #ffffff;
        color: #0981ff;
        line-height: 40px;
        border-radius: 20px;
      }

      .btnType2 {
        width: 80px;
        min-width: max-content;
        height: 40px;
        background: #0981ff;
        color: white;
        line-height: 40px;
        border-radius: 20px;
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    justify-content: space-between;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
}
/deep/.el-table th.el-table__cell > .cell {
    // display: flex;
    // justify-content: center;
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
.isdeal1 {
  border:1px solid #f59a23;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 12px;
  margin: 0 auto;
  border-radius: 5px;
  color: #f59a23;
  text-align: center;
  margin-left: 5px;
}
.button_top {
    // margin-bottom: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    // min-width: 200px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 80px;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 80px;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
</style>
